&.assortiment-overview {

	// bundle-overview-section
	.bundle-overview-section {
		.container-two-columns {
			.container-holder {
				.column {
					&.one {
						@include media-breakpoint-up(md) {
							@include make-col(3);
						}
					}

					&.two {
						padding-left: 15px !important;

						@include media-breakpoint-up(md) {
							@include make-col(9);
						}
					}
				}
			}
		}

		// sidebar-filter
		.sidebar-filter {
			@include media-breakpoint-down(sm) {
				display: none;
			}

			&.open {
				display: block;
				position: fixed;
				z-index: 999;
				top: 0;
				left: 0;
				bottom: 0;
				width: 100%;
				max-width: 360px;
				height: 100%;
				padding: 20px 20px 80px 20px;
				background-color: rgba($white, 0.9);
				box-shadow: $shadow;
				overflow-x: hidden;

				.heading,
				.close-btn {
					display: block;
				}
			}

			.heading {
				display: none;
				margin-bottom: 1rem;
				font-size: 18px;
				font-weight: 700;
			}

			.close-btn {
				display: none;
				position: absolute;
				top: 20px;
				right: 20px;
				color: $red;
				font-size: 18px;
				line-height: 1;
			}

			.form-group {
				margin-bottom: 1.5rem;

				&.closed {
					.form-group-content {
						display: none;
					}
				}

				.form-group-title {
					display: flex;
					justify-content: space-between;
				}
			}
		}

		// collection
		.collection {
			&.grid {
				.grid-items {
					.item {
						@include make-col(12);

						&.hidden-item {
							display: none;
						}

						.card {
							@include media-breakpoint-up(lg) {
								flex-direction: row;
							}

							.card-image {
								@include media-breakpoint-up(lg) {
									@include make-col(5);
								}

								.card-image-link {
									height: 100%;

									.card-img {
										height: 100%;
										object-fit: cover;
									}

									.card-image-label {
										display: none;
									}
								}
							}

							.card-body {
								@include media-breakpoint-up(lg) {
									@include make-col(7);
								}

								padding-top: 40px;

								.card-label {
									display: block !important;
									position: absolute;
									top: 0;
									right: 0;
									padding: 0.5rem 1rem;
									background-color: $blue;
									border-radius: 0 6px;
									box-shadow: 3px 3px 6px rgba($black, 0.15);
									color: $white;
									font-size: 12px;
									font-weight: 700;
									line-height: 22px;
								}

								.card-description {
									ul {
										margin: 0;
										padding: 0;
										list-style: none;
										color: $blue;
										font-size: 12px;
										font-weight: 500;

										li {
											position: relative;
											display: flex;
											align-items: center;
											margin: 10px 0;
											padding-left: 30px;

											.icon {
												position: absolute;
												left: 0;
												font-size: 18px;

												i {
													font-weight: 300;
												}
											}
										}
									}
								}

								.card-prices {
									.card-price {
										text-align: right;

										.card-price-value {
											font-size: 20px;
											font-weight: 700;
										}
									}
								}

								.card-buttons {
									text-align: right;
								}
							}
						}
					}
				}
			}
		}

		// fixed-filter-button
		.fixed-filter-button {
			@include media-breakpoint-up(md) {
				display: none;
			}

			z-index: 998;
			position: fixed;
			left: 15px;
			right: 15px;
			bottom: 15px;
			text-align: center;

			.btn {
				@extend .btn;
				@extend .btn-primary;
				display: flex;
				justify-content: center;

				i {
					margin-right: 10px;
				}

				&::after {
					display: none;
				}
			}
		}
	}
}


&.assortiment-detail {
	.collection-section {
		overflow: hidden;

		.assortiment-related-items {
			margin-top: 1rem;
		}

		.all-results {
			margin-top: 1rem;
		}
	}
}
