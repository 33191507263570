// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	padding: 15px 0;
	background: $blue-dark;
	box-shadow: $shadow;
	transition: 0.5s;

	@include media-breakpoint-up(xl) {
		padding-left: 15px;
		padding-right: 15px;
	}

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				align-items: flex-end;
				justify-content: flex-end;

				@include media-breakpoint-down(lg) {
					align-items: center;
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// logo
	.logo {
		margin: 5px auto 5px 0;
		transition: 0.5s;

		a {
			display: block;

			img {
				width: 100%;
			}
		}

		&.logo-hacienda {
			width: 80px;
		}

		&.logo-dreamhome {
			width: 278px;
			margin-top: 15px;
			margin-bottom: 14px;

			@include media-breakpoint-down(xs) {
				width: 245px;
			}
		}
	}

	// top-menu
	.top-menu {
		@include media-breakpoint-down(lg) {
			display: none;
		}

		position: absolute;
		top: -7px;
		right: 70px;
		flex-grow: 0;

		.navbar-nav {
			>.nav-item {
				margin-left: 15px;
				font-size: 14px;
				font-weight: 300;

				>.nav-link {
					display: flex;
					align-items: center;
					color: $blue-light;

					&:hover {
						color: $yellow;
					}
				}

				&.active {
					>.nav-link {
						color: $yellow;
					}
				}
			}

			.nav-item {
				.dropdown-menu {
					padding: 0.5rem;
					background-color: $cream;
					border-color: $cream;

					&::before,
					&::after {
						border-bottom-color: $cream;
					}

					.nav-item {
						font-size: 14px;
						font-weight: 4000;
						line-height: 1.2;
						white-space: nowrap;

						.nav-link {
							color: $blue-dark;

							&:hover {
								color: $blue;
							}
						}

						&.active {
							.nav-link {
								color: $blue;
							}
						}
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		position: absolute;
		top: 0;
		right: 15px;

		.navbar-nav {
			&.language {
				.nav-item {
					.nav-link {
						display: flex;
						align-items: center;
						border: none;

						img {
							border: 1px solid $white;
							border-radius: 100%;
						}

						&::after {
							color: $white;
						}
					}

					.dropdown-menu {
						.dropdown-item {
							img {
								width: 22px;
								height: 22px;
							}
						}
					}
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			width: 36px;
			height: 36px;
			margin: auto 30px auto 15px;
			padding: 0;
			border: none;
			background-color: $white;
			border-radius: 100%;
			color: $blue-dark;
			font-size: 18px;
			transition: color 0.5s;

			i {
				line-height: 36px;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// main-menu
	.main-menu {
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				padding-top: 20px;

				>.nav-item {
					border-top: 1px solid rgba($white, 0.25);

					>.nav-link {
						display: flex;
						align-items: center;
						color: $white;
						font-size: 16px;
						font-weight: 700;

						&:hover {
							color: $yellow;
						}
					}

					&.active {
						>.nav-link {
							color: $yellow;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 14px;

							.nav-link {
								color: $white;

								&:hover {
									color: $yellow;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $yellow;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			flex-grow: 0;

			.navbar-nav {
				>.nav-item {
					display: flex;
					align-items: center;
					margin-left: 15px;
					font-size: 16px;
					font-weight: 700;

					>.nav-link {
						color: $white;

						&:hover {
							color: $yellow;
						}
					}

					&.active {
						>.nav-link {
							color: $yellow;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 0.5rem;
						background-color: $cream;
						border-color: $cream;

						&::before,
						&::after {
							border-bottom-color: $cream;
						}

						.nav-item {
							font-weight: 600;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $blue-dark;

								&:hover {
									color: $blue;
								}
							}

							&.active {
								.nav-link {
									color: $blue;
								}
							}
						}
					}
				}
			}
		}
	}

	// book-button
	div.book-button {
		margin: auto 0 0 60px;

		a {
			display: inline-flex;
			color: $yellow;
			font-size: 16px;

			@include media-breakpoint-down(xs) {
				font-size: 12px;
			}

			font-weight: 700;
			line-height: 40px;
			text-decoration: none;

			&::after {
				content: '\f30b';
				font-family: $font-awesome;
				margin-left: 10px;
				font-size: 18px;

				@include media-breakpoint-down(xs) {
					font-size: 16px;
				}

				font-weight: 400;
				transition: 0.5s;
			}

			&:hover {
				color: $white;

				&::after {
					transform: translateX(5px);
					color: $white;
				}
			}
		}
	}

	&.sticky {
		padding-top: 5px;
		padding-bottom: 5px;

		.container-fluid .container-holder>.column .top-menu {
			@include media-breakpoint-up(xl) {
				display: none !important;
			}
		}

		div.book-button {
			margin-top: 0;
		}

		.site-switcher {
			display: none !important;
		}

		.logo {
			&.logo-hacienda {
				width: 55px;
			}

			&.logo-dreamhome {
				width: 246px;

				@include media-breakpoint-down(xs) {
					margin-top: 6px;
					margin-bottom: 6px;
				}
			}
		}

		.container,
		.container-fluid {
			.container-holder {
				>.column {
					align-items: center;
				}
			}
		}
	}
}

&.home {
	.header {
		position: fixed;
	}
}
