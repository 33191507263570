// footer
.footer {
	font-size: 14px;

	// footer-partners
	.footer-partners {
		margin-top: calc(6vh + 22.5px);
		padding-bottom: calc(6vh + 22.5px);
		text-align: center;

		.footer-text {
			ul {
				display: flex;
				flex-flow: row wrap;
				align-items: center;
				justify-content: center;
				margin: 20px 0 0 0;
				padding: 0;
				list-style: none;

				li {
					margin: 5px;
				}
			}
		}
	}

	// footer-outro
	.footer-outro {
		position: relative;
		padding: 6vh 0;
		background: linear-gradient(0deg, rgba($blue-dark, 0.8) 0%, rgba($blue-dark, 0.8) 100%), url('/images/footer-bg.jpg') no-repeat top center;
		background-size: cover;
		color: $white;

		&::before {
			content: '';
			z-index: 1;
			position: absolute;
			top: -22.5px;
			left: 0;
			right: 0;
			height: 45px;
			background: url('/images/wave-footer.svg') repeat-x;
		}

		// two-columns
		.container-two-columns {
			margin-bottom: 6vh;

			.container-holder {
				align-items: center;

				.column {
					&.one {
						padding-right: 15px !important;
					}

					&.two {
						padding-left: 15px !important;
					}
				}

				@include media-breakpoint-down(sm) {
					flex-direction: column-reverse;

					img {
						max-width: 250px;
					}
				}
			}
		}

		// four-columns
		.container-four-columns {
			.container-holder {
				margin-bottom: -30px;

				.column {
					margin-bottom: 30px;
				}
			}
		}

		a:not(.btn) {
			color: $white;
			font-weight: 500;

			&:hover {
				color: $yellow;
			}
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}

		h2 {
			font-weight: 700;
		}

		h5 {
			margin-bottom: 20px;
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin-bottom: 0.5rem;
			}
		}

		// contact-info
		.contact-info {
			ul {
				position: relative;
				margin-bottom: 1rem;
				padding-left: 25px;

				li {
					margin-bottom: 0;

					a {
						color: $blue-neon;

						&:hover {
							color: $yellow;
						}
					}
				}
			}

			ul:first-of-type {
				&::before {
					content: '\f3c5';
					position: absolute;
					top: 0;
					left: 0;
					font-family: $font-awesome;
					font-weight: 300;
				}
			}

			ul:nth-of-type(2) {
				&::before {
					content: '\f879';
					position: absolute;
					top: 0;
					left: 0;
					font-family: $font-awesome;
					font-weight: 300;
				}
			}

			ul:last-of-type {
				&::before {
					content: '\f0e0';
					position: absolute;
					top: 0;
					left: 0;
					font-family: $font-awesome;
					font-weight: 300;
				}
			}
		}

		// footer-logolink
		.footer-logolink {
			ul {
				li {
					margin-right: 15px;

					a {
						display: block;
						font-size: 24px;
					}
				}
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		padding: 30px 0;

		.footer-text {
			ul {
				display: flex;
				flex-direction: column;
				margin: 0;
				padding: 0;
				list-style: none;

				@include media-breakpoint-up(lg) {
					flex-direction: row;
				}

				li {
					@include media-breakpoint-up(lg) {
						margin-right: 20px;
					}

					color: $blue;

					a {
						color: $blue;

						&:hover {
							color: $blue-dark;
						}
					}
				}
			}
		}
	}
}

&.home {
	.footer {

		// footer-partners
		.footer-partners {
			margin-top: 0;
			padding-top: 22.5px;
			background-color: $cream;
		}
	}
}
