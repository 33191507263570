// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	.owl-caption-holder {
		height: 100%;
		display: flex;
		align-items: flex-end;
		padding-bottom: 9vh;
		background: linear-gradient(rgba(black, 0) 70%, rgba(black, 0.65) 100%);

		.owl-caption {
			height: 100%;
			z-index: 900;
		}
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 75vh;
	min-height: 300px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		min-height: 540px;
		height: 100vh;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;

	// cta-badge
	.cta-badge {
		z-index: 100;
		position: absolute;
		top: 190px;
		right: 30px;

		.grid-items {
			margin: 0 !important;

			.item {
				flex: 100% !important;
				max-width: unset !important;
				margin: 0 !important;
				padding: 0 !important;

				.card {
					display: flex;
					flex-direction: row;
					background-color: $blue;
					border: none;
					border-radius: 6px;
					overflow: visible;

					.card-image {
						margin: -55px 0 0 0;
					}

					.card-body {
						padding: 1px 25px 5px 15px;

						.card-caption {
							color: $white;

							.card-title {
								margin: 0;
								font-size: 36px;
								font-family: $font-family-secondary;

								&:hover {
									color: inherit;
								}
							}

							.card-subtitle {
								display: inline-flex;
								align-items: center;
								margin: 0;
								font-size: 14px;
								font-weight: 400;
								line-height: 1;
								font-family: $font-family-primary;

								&::after {
									content: "\f30b";
									font-family: $font-awesome;
									margin-left: 10px;
									font-size: 18px;
									font-weight: 400;
									transition: 0.5s;
								}
							}

							.card-description {
								display: none;
							}
						}
					}

					&:hover {
						background-color: $blue-dark;

						.card-subtitle {
							&::after {
								transform: translateX(5px);
							}
						}
					}
				}
			}
		}
	}
}
