// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark,
	&.bg-white {
		margin: 0;
		padding-top: 6vh;
		padding-bottom: 6vh;
	}

	&.bg-light {
		position: relative;

		&::before {
			content: '';
			z-index: 10;
			position: absolute;
			top: -22.5px;
			left: 0;
			right: 0;
			height: 45px;
			background: url('/images/wave-cream.svg') repeat-x;
		}

		&::after {
			content: '';
			z-index: 10;
			position: absolute;
			left: 0;
			right: 0;
			bottom: -22.5px;
			height: 45px;
			background: url('/images/wave-cream.svg') repeat-x;
		}
	}

	&.bg-dark {
		position: relative;

		&::before {
			content: '';
			z-index: 10;
			position: absolute;
			top: -22.5px;
			left: 0;
			right: 0;
			height: 45px;
			background: url('/images/wave-blue.svg') repeat-x;
		}

		&::after {
			content: '';
			z-index: 10;
			position: absolute;
			left: 0;
			right: 0;
			bottom: -22.5px;
			height: 45px;
			background: url('/images/wave-blue.svg') repeat-x;
		}
	}

	&.bg-white {
		position: relative;

		&::before {
			content: '';
			z-index: 10;
			position: absolute;
			top: -22.5px;
			left: 0;
			right: 0;
			height: 45px;
			background: url('/images/wave-white.svg') repeat-x;
		}

		&::after {
			content: '';
			z-index: 10;
			position: absolute;
			left: 0;
			right: 0;
			bottom: -22.5px;
			height: 45px;
			background: url('/images/wave-white.svg') repeat-x;
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	position: relative;
	padding-top: 6vh;
	padding-bottom: calc(6vh + 22.5px);
	background-color: $cream;
	color: $blue-dark;
	font-size: 16px;
	font-weight: 500;

	&::after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: -22.5px;
		height: 45px;
		background: url('/images/wave-white.svg') repeat-x;
	}

	.container-one-column {
		.container-holder {
			max-width: 960px;
			margin: 0 auto;
			text-align: center;
		}
	}

	// icon-collection
	.icon-collection {
		margin-top: 30px;
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;

		// property-label
		.property-label {
			@extend .btn;
			@extend .btn-red;
			pointer-events: none;

			&::after {
				display: none;
			}
		}
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		.downloads {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
	padding: 22.5px 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.slider {
			&.owl-carousel .owl-stage-outer {
				overflow: initial;
			}

			.owl-item {
				opacity: 0;
				transition: opacity 0.25s linear;
			}

			.owl-item.active {
				opacity: 1;
			}
		}

		.card {
			@extend .default-card !optional;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	position: relative;

	// owl-carousel
	.owl-carousel {
		height: 75vh;
		min-height: 360px;
		max-height: 720px;

		.item {
			align-items: flex-end;
			height: 75vh !important; // overide
			min-height: 360px;
			max-height: 720px;
			padding: 22.5px 0; // overide

			&::after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 50%;
				background: linear-gradient(0deg, rgba($black, 0.5) 0%, rgba($black, 0) 100%);
			}

			.owl-container {
				z-index: 1;
				position: relative;
				max-width: 960px;

				.owl-caption {
					display: flex;
					flex-direction: column;
					margin-bottom: calc(6vh - 22.5px);
					text-shadow: none;

					.owl-subtitle {
						order: -1;
						margin: 0;
						font-size: 36px;
						font-family: $font-family-secondary;
					}

					.owl-title {
						@include font-size($h1-font-size);
						margin: 0;
						font-weight: 700;
					}
				}
			}
		}

		// restaurant-banner
		&.restaurant-banner {
			.owl-caption {
				&::before {
					content: '';
					order: -2;
					display: block;
					max-width: 391px;
					max-height: 327px;
					width: 50%;
					height: 100%;
					margin: 0 auto 30px auto;
					background: url('/images/vista-lago-logo.svg') no-repeat center bottom;
					background-size: contain;
					aspect-ratio: 1;
				}
			}
		}

		.owl-dots,
		.owl-nav {
			display: none;
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;

	.gallery-label {
		display: none;
	}
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}

// outro-section
// =========================================================================
.outro-section {
	@extend .bg-light;
	margin-top: calc(6vh + 22.5px) !important;
	margin-bottom: calc(6vh + 22.5px) !important;
	font-size: 16px;
	font-weight: 500;

	.container-one-column {
		.container-holder {
			max-width: 960px;
			margin: 0 auto;
			text-align: center;
		}
	}
}
