// btn
.btn {
	display: inline-flex;
	align-items: center;
	padding: 0.75rem 1.25rem;
	border-radius: 6px;
	box-shadow: 3px 3px 6px rgba($black, 0.15);
	font-size: 14px;
	font-weight: 700;
	line-height: 22px;

	&::after {
		content: '\f30b';
		font-family: $font-awesome;
		margin-left: 10px;
		font-size: 18px;
		font-weight: 400;
		transition: 0.5s;
	}

	&:hover {
		&::after {
			transform: translateX(5px);
		}
	}

	// btn-primary
	&.btn-primary {
		&:hover {}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}

	// btn-red
	&.btn-red {
		background-color: $red;
		border-color: $red;
		color: $white;

		&:hover {
			background-color: darken($red, 50%);
			border-color: darken($red, 50%);
			color: $white;
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-red;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
