& {
	font-size: 14px;

	.form-control {
		font-size: 14px;
	}
}

// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-large {
		max-width: 960px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

.container-fluid {
	max-width: 1920px;
}

// headings
h4,
.h4 {
	margin-bottom: 0;
	font-weight: 700;
	font-family: $font-family-secondary;
}

h6 {
	font-weight: 600;
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// gallery
.gallery,
.gallery-horizontal {
	.gallery-item {
		.gallery-link {
			box-shadow: $shadow;
		}
	}
}

// default-card
.default-card {
	border: none;
	border-radius: 10px;
	box-shadow: 0 0 12px rgba($black, 0.15);

	.card-body {
		padding: 25px 30px 30px 30px;

		.card-label {
			display: none;
		}

		.card-caption {
			display: flex;
			flex-direction: column;

			.card-subtitle {
				order: -1;
				margin: 0;
				color: $blue;
				font-size: 24px;
			}

			.card-title {
				color: $blue-dark;
				font-weight: 700;
			}

			.card-description {
				font-size: 14px;
				font-weight: 500;

				.list {
					margin: 30px 0;
					color: $blue;
					font-size: 12px;

					.list-item {
						display: flex;
						align-items: center;
						margin: 10px 0;

						.icon {
							font-size: 18px;

							i {
								font-weight: 300;
							}
						}
					}
				}
			}
		}
	}
}

// icon-collection
.icon-collection {
	&.grid {
		.grid-items {
			margin-bottom: -45px;

			.item {
				margin-bottom: 45px;

				@include media-breakpoint-down(xs) {
					@include make-col(6);
				}

				@include media-breakpoint-up(xl) {
					flex: 1 !important;
					max-width: none !important;
				}

				.card {
					background: none;
					border: none;
					border-radius: 0;

					.card-image {
						margin: 0 auto 15px auto;

						i {
							font-size: 36px;
						}
					}

					.card-body {
						padding: 0;
						text-align: center;

						.card-caption {
							.card-title {
								margin-bottom: 5px;
								color: $blue;
								font-size: 24px;
								font-weight: 700;
								font-family: $font-family-secondary;

								@include media-breakpoint-down(xs) {
									font-size: 20px;
								}
							}

							.card-subtitle {
								margin: 0;
								color: $blue-dark;
								font-size: 14px;
								font-family: $font-family-primary;

								@include media-breakpoint-down(xs) {
									font-size: 12px;
								}
							}

							.card-description {
								color: $blue-dark;
								font-size: 14px;
								font-weight: 500;

								p {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}

// large-collection
.large-collection {
	&.grid {
		.grid-items {
			.item {
				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				@include media-breakpoint-up(xl) {
					@include make-col(6);
				}
			}
		}
	}

	.card {
		@extend .default-card;

		.card-body {
			position: relative;
			padding: 40px 20px 30px 20px;

			@include media-breakpoint-up(lg) {
				padding: 50px 40px 40px 40px;
			}

			.card-caption {
				.card-title {
					color: $blue-dark;
					font-weight: 700;
				}

				.card-subtitle {
					@extend .btn;
					position: absolute;
					bottom: calc(100% - 24px);
					margin-bottom: 0;
					background-color: $red;
					border-color: $red;
					color: $white;
					font-weight: 400 !important;
					font-family: $font-family-primary;

					&:hover {
						background-color: darken($red, 50%);
						border-color: darken($red, 50%);
						color: $white;
					}
				}

				.card-description {
					font-size: 14px;
					font-weight: 500;
				}
			}

			.card-buttons {
				.card-btn {
					background-color: $blue !important;
					border-color: $blue !important;

					&:hover {
						background-color: darken($blue, 50%) !important;
						border-color: darken($blue, 50%) !important;
					}
				}
			}
		}
	}
}

// portrait-collection
.portrait-collection {
	.card {
		border: none;
		border-radius: 6px;
		box-shadow: 0 0 12px rgba($black, 0.15);

		.card-img-overlay {
			display: flex;
			flex-direction: column;
			top: auto;
			min-height: 50%;
			border-radius: 0;
			background: linear-gradient(0deg, rgba($black, 0.5) 0%, rgba($black, 0) 100%);

			@include media-breakpoint-up(md) {
				padding: 25px;
			}

			.card-caption {
				display: flex;
				flex-direction: column;
				flex: 0;
				margin-top: auto;
				text-shadow: none;

				.card-title {
					@include font-size($h2-font-size);
					margin: 0;
					font-weight: 700;

					&:hover {
						color: $white;
					}
				}

				.card-subtitle {
					order: -1;
					margin: 0;
					font-size: 36px;
					font-family: $font-family-secondary;
				}

				.card-description {
					display: none;
				}
			}
		}
	}
}

// link-arrow
.link-arrow {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-right: 26px;
	font-size: 14px;
	font-weight: 500;
	text-decoration: underline;

	&::after {
		content: '\f30b';
		position: absolute;
		right: 0;
		margin-left: 10px;
		font-size: 18px;
		line-height: 1;
		font-weight: 400;
		font-family: $font-awesome;
		transition: 0.5s;
	}

	&:hover {
		&::after {
			transform: translateX(5px);
		}
	}
}

// link
div.link {
	.link {
		@extend .link-arrow;
	}
}

// link-group
.link-group {
	flex-flow: row wrap !important;
	justify-content: space-evenly;
}

// usp-list
div.usp-list {
	ul {
		display: flex;
		flex-flow: row wrap;
		margin: -10px 0;

		li {
			display: flex;
			align-items: center;
			margin: 10px 0;

			@include media-breakpoint-up(md) {
				width: 50%;
			}

			img {
				max-width: 38px;
				max-height: 38px;
				margin-right: 10px;
				filter: brightness(0) saturate(100%) invert(29%) sepia(39%) saturate(3623%) hue-rotate(174deg) brightness(99%) contrast(101%);
			}

			i {
				width: 38px;
				min-width: 38px;
				height: 38px;
				margin-right: 10px;
				color: $blue;
				font-size: 30px;
				text-align: center;
			}
		}
	}
}
