// mini-sab
.mini-sab {
	background-color: $cream;

	.container {
		position: relative;
		display: flex;
		justify-content: center;

		.container-holder {
			z-index: 100;
			position: relative;
			min-height: 80px;
			margin: 0;
			padding: 15px 0;
			border-radius: 40px;
			background: $yellow;
			box-shadow: 0 0 12px rgba($black, 0.15);
			color: #fff;

			@include media-breakpoint-down(md) {
				margin-top: -50px;
			}

			@include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 80px;
			}

			// tabs
			div.tabs {
				position: absolute;
				top: -52px;
				left: 60px;

				@include media-breakpoint-down(md) {
					left: 30px;
				}

				ul {
					display: flex;
					align-items: flex-end;
					justify-content: flex-end;

					li {
						margin-right: 6px;

						@include media-breakpoint-down(md) {
							margin-right: 3px;
						}

						a {
							display: flex;
							align-items: center;
							height: 31px;
							padding: 0 15px;
							background-color: $cream;
							border-radius: 6px 6px 0 0;
							color: $blue-dark;
							font-size: 12px;
							line-height: 31px;
							text-decoration: none;

							&:hover {
								background-color: $yellow;
							}

							i {
								font-size: 14px;
							}

							.list-item-title {
								margin-left: 10px;
							}
						}

						&:not(:first-of-type) {
							a {
								box-shadow: inset 0 -2px 3px rgba($black, 0.15);

								.list-item-title {
									@include media-breakpoint-down(md) {
										display: none;
									}
								}
							}
						}

						&:first-of-type {
							a {
								height: 37px;
								background-color: $yellow;
								border-radius: 6px 6px 0 0;
								font-weight: 700;
								line-height: 37px;

								i {
									font-size: 18px;
								}
							}
						}
					}
				}
			}
		}
	}
}
